import React, { useRef } from 'react';
import './Join.css';
import emailjs from '@emailjs/browser'

const Join = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_5gduvxl', 'template_zwo3ucl', form.current, '_CtDhf_iN1jBqjuVK')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <div className="Join" id="join-us">
        {/* left join sec */}
        <div className="left-j">
          <hr />
            <div>
                <span className='stroke-text'>Ready to</span>
                <span>level up</span>
            </div>
            <div>
                <span>your body</span>
                <span>with us</span>
            </div>
        </div>

        {/* right join sec */}
        <div className="right-j">
          <form  ref={form} className="email-container" onSubmit={sendEmail}>
            <input type="email" name="user_email" placeholder='Enter your email address' />
            <button className='btn btn-j'>Join now</button>
          </form>
        </div>
    </div>
  )
}

export default Join